import React, { useEffect } from "react";

const EconomicCalendar = () => {
  useEffect(() => {
    // Check if the script has already been added
    if (!document.getElementById("tradingview-widget-script")) {
      initChart();
    }
  }, []);

  const initChart = () => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
    script.async = true;
    script.id = "tradingview-widget-script"; // Add an ID to prevent multiple instances
    script.innerHTML = JSON.stringify({
      colorTheme: "dark",
      isTransparent: false,
      width: "100%",
      height: "600",
      locale: "en",
      importanceFilter: "-1,0,1",
    });
    document.getElementById("economic-calendar-widget").appendChild(script);
  };

  return (
    <div className="tradingview-widget-container">
      <div id="economic-calendar-widget"></div>
    </div>
  );
};

export default EconomicCalendar;
